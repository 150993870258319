import React, { useState, useEffect } from "react";
import {
  Button,
  Container,
  Typography,
  IconButton,
  Grid,
  useMediaQuery,
  CircularProgress,
  Box,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import CloseIcon from "@mui/icons-material/Close";
import Mail from "../api/mail"; // Ensure correct import according to your project structure
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import MenuSection from "../components/MenuSection";
import sugerirAcumularServiclub from "../api/sugerirAcumularServiclub";

const FinalizarPago = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const clienteString = localStorage.getItem("cliente");
  const [cliente, setCliente] = useState(clienteString ? JSON.parse(clienteString) : {});
  const [alertOpen, setAlertOpen] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [emailInput, setEmailInput] = useState(cliente.email || "");
  const isScreenWidthLessThan600 = useMediaQuery(theme => theme.breakpoints.down("sm")); // Call useMediaQuery at the top level
  const theme = useTheme();
  const handleFinalizarTransaccion = () => {
    navigate("/home");
  };
  const [isCheckingPoints, setIsCheckingPoints] = useState(false);
  const [showPuntosModal, setShowPuntosModal] = useState(false);
  const bandera = localStorage.getItem("bandera"); // Suponiendo que lo estás guardando así
  const despachosSeleccionadosString = localStorage.getItem("despachosSeleccionados");
  const nic = localStorage.getItem("formattedNic");
  const vengoDeQR = localStorage.getItem("vengoDeQR");

  if (despachosSeleccionadosString) {
    localStorage.setItem("despachoPagado", true);
  } else {
    localStorage.setItem("despachoPagado", false);
  }

  const handleAlertOpen = (message, success) => {
    setAlertMessage(message);
    setIsValid(success);
    setAlertOpen(true);
    setTimeout(() => {
      setAlertOpen(false);
    }, 3000);
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleSendMail = async () => {
    if (!cliente || !cliente.email) {
      console.log(cliente.email);
      handleModalOpen();
      return;
    }
    setIsLoading(true);
    try {
      await Mail.sendEmail(cliente.email);
      handleAlertOpen(`El comprobante fue enviado exitosamente a ${cliente.email}`, true);
    } catch (error) {
      console.error("Error al enviar el mail:", error);
      handleAlertOpen("Error al enviar el comprobante. Intente de nuevo.", false);
    } finally {
      setIsLoading(false);
    }
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
  };

  const handleEmailSubmission = async () => {
    if (emailInput) {
      const updatedClient = { ...cliente, email: emailInput };
      setCliente(updatedClient);
      localStorage.setItem("cliente", JSON.stringify(updatedClient)); // Actualiza el almacenamiento local
      setIsLoading(true);
      handleModalClose();
      try {
        await Mail.sendEmail(); // Ahora la función debe recoger el correo actualizado
        handleAlertOpen(`El comprobante fue enviado exitosamente a ${emailInput}`, true);
      } catch (error) {
        console.error("Error al enviar el mail:", error);
        handleAlertOpen("Error al enviar el comprobante. Intente de nuevo.", false);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    const verificarAcumulacionPuntos = async () => {
      const cargaPuntosServiClub = localStorage.getItem("cargaPuntosServiClub");
      // alert(cargaPuntosServiClub)
      // http://ypf.eberaza.com.ar.caldenoil.com:5001
      if (nic === "http://ypf.eberaza.com.ar.caldenoil.com:5001" && cargaPuntosServiClub !== "true" && vengoDeQR != "true") {
        try {
          setIsCheckingPoints(true); // Muestra el spinner
          const response = await sugerirAcumularServiclub.acumulaPuntos(); // <-- Importalo si no lo hiciste

          console.log("Respuesta API Puntos YPF:", response);

          if (response?.status === 200) {
            setTimeout(() => {
              setIsCheckingPoints(false);
              setShowPuntosModal(true);
            }, 2000);
          } else {
            alert("No se pueden acumular puntos");
            console.log("No se pueden acumular puntos");
            localStorage.removeItem("cargaPuntosServiClub");
          }
        } catch (error) {
          console.error("Error verificando acumulación de puntos:", error);
        }
      } else {
        setIsCheckingPoints(false); // Si no es YPF, quita el spinner
      }
    };

    verificarAcumulacionPuntos();
  }, []);

  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    // Función para manejar el evento cuando se presiona el botón de retroceso
    // Aquí puedes redirigir a otra página o simplemente hacer nada
    navigate("/finalizarpago"); // Redirecciona a otra página según sea necesario
  };

  return (
    <>
      <MenuSection />

      {/* SPINNER de verificación Serviclub */}
      {isCheckingPoints && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "80vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: 2000,
          }}
        >
          <CircularProgress style={{ color: "#282c34" }} />
          <Typography
            variant="subtitle1"
            style={{
              fontSize: "1.25rem",
              marginTop: "20px",
              color: "#282c34",
              fontWeight: "bold",
              textAlign: "center",
            }}
          >
            Verificando beneficios Serviclub...
          </Typography>
        </Box>
      )}

      {/* MODAL SERVICLUB */}
      {showPuntosModal && (
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          style={{
            height: "80vh",
            width: "100vw",
            position: "fixed",
            top: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: 3000,
            padding: "20px",
            textAlign: "center",
          }}
        >
          <Typography variant="h5" style={{ fontWeight: "bold", marginBottom: "15px" }}>
            ¿Desea sumar los puntos a su tarjeta Serviclub?
          </Typography>
          <Typography variant="body1" style={{ marginBottom: "30px" }}>
            Puede acumular puntos Serviclub con esta transacción.
          </Typography>
          <Box display="flex">
            <Button
              variant="contained"
              sx={{
                width: "100px",
                height: "50px",
                fontWeight: "bold",
                backgroundColor: theme.bandera.colorSecundario,
                mr: 2, // margen derecho
              }}
              onClick={() => {
                setShowPuntosModal(false);
                localStorage.setItem("cargaPuntosServiClub", "false");
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              sx={{ width: "100px", height: "50px", fontWeight: "bold", backgroundColor: theme.bandera.colorSecundario }}
              onClick={() => {
                setShowPuntosModal(false);
                localStorage.setItem("cargaPuntosServiClub", "true");
                navigate("/listadoqrpage");
              }}
            >
              Sí
            </Button>
          </Box>
        </Box>
      )}

      {/* CONTENIDO PRINCIPAL */}
      {!isCheckingPoints && !showPuntosModal && (
        <Container
          style={{
            height: "90vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: isLoading ? "white" : "#4dcc04",
            position: "relative",
            top: 20,
          }}
        >
          {!isLoading && (
            <IconButton style={{ position: "absolute", top: 20, right: 10 }} onClick={() => navigate("/home")}>
              <CloseIcon />
            </IconButton>
          )}

          {isLoading ? (
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              style={{
                height: "100vh",
                width: "100vw",
                position: "fixed",
                top: 0,
                left: 0,
              }}
            >
              <CircularProgress style={{ color: "#282c34" }} />
              <Typography
                variant="subtitle1"
                style={{
                  fontSize: "1.25rem",
                  marginTop: "20px",
                  color: "#282c34",
                  fontWeight: "bold",
                  textAlign: "center",
                }}
              >
                Enviando comprobante a {cliente.email}
              </Typography>
            </Box>
          ) : (
            <>
              <Typography variant="h4" align="center" gutterBottom>
                <CheckCircleOutlineIcon
                  sx={{ color: "white", fontSize: "150px" }}
                  style={{
                    transform: isScreenWidthLessThan600 ? "translate(0, -40px)" : "translate(0, -40px)",
                  }}
                />
              </Typography>

              <Grid container spacing={2} width="100%" maxWidth="300px" justifyContent="center">
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    onClick={handleSendMail}
                    disabled={isLoading}
                  >
                    Enviar comprobante <br /> por mail
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      fontSize: "17px",
                      fontWeight: "bold",
                      borderRadius: "10px",
                      width: "100%",
                    }}
                    onClick={handleFinalizarTransaccion}
                  >
                    Finalizar <br /> transacción
                  </Button>
                </Grid>
              </Grid>
            </>
          )}

          {/* ALERTA MAIL */}
          {alertOpen && (
            <Alert
              severity={isValid ? "success" : "error"}
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" style={{ fontSize: "80px" }} />,
                error: <HighlightOffIcon fontSize="inherit" style={{ fontSize: "80px" }} />,
              }}
              sx={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "white",
                zIndex: 1000,
                "& .MuiAlert-message": {
                  fontSize: "1.25rem",
                  textAlign: "center",
                  fontWeight: "bold",
                },
              }}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  onClick={handleAlertClose}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    fontSize: "25px",
                  }}
                >
                  <HighlightOffIcon fontSize="inherit" />
                </IconButton>
              }
            >
              {alertMessage}
            </Alert>
          )}

          {/* MODAL INGRESO EMAIL */}
          <Dialog
            open={modalOpen}
            onClose={handleModalClose}
            sx={{
              "& .MuiDialog-paper": {
                backgroundColor: theme.palette.background.default,
                boxShadow: theme.shadows[5],
                borderRadius: theme.shape.borderRadius,
                display: "flex",
                flexDirection: "column",
              },
            }}
          >
            <DialogTitle>Cliente sin email</DialogTitle>
            <DialogContent>
              <DialogContentText sx={{ fontSize: "1.15rem" }}>El cliente no tiene un email asignado. Por favor, ingrese un email válido.</DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                id="name"
                type="email"
                fullWidth
                variant="standard"
                value={emailInput}
                onChange={e => setEmailInput(e.target.value)}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleModalClose} color="primary">
                <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Cancelar
                </Typography>
              </Button>

              <Button onClick={handleEmailSubmission} color="primary" autoFocus>
                <Typography variant="body1" style={{ fontSize: "16px", fontWeight: "bold" }}>
                  Enviar
                </Typography>
              </Button>
            </DialogActions>
          </Dialog>
        </Container>
      )}
    </>
  );
};

export default FinalizarPago;
