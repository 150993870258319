import axios from "axios";

const getVersionCaldenOil = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");

  const userData = {
    nic: formattedNic,
    token: token,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/version/versionCaldenOil`, userData);
  return response.data; // Lanza automáticamente cualquier error hacia el llamado superior
};

const version = {
  getVersionCaldenOil,
};

export default version;
