import "./App.css";
import { useState, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { FiAlertTriangle } from "react-icons/fi";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { compareVersions } from "compare-versions";
import Footer from "./components/Footer";
import version from "./api/versionCaldenOil";

// Páginas y componentes
import Login from "./pages/Login";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import PagoConMercadopago from "./pages/PagoConMercadopago";
import TicketCanvas from "./components/GenerateImage";
import Comprobante from "./components/Comprobante";
import FinalizarPago from "./pages/FinalizarPago";
import SignaturePadComponent from "./components/SignaturePadComponent";
import ClientesSearchPage from "./pages/ClientesSearchPage";
import ArticulosSearchPage from "./pages/ArticulosSearchPage";
import DespachosSearchPage from "./pages/DespachosSearchPage";
import FormasDePagoPage from "./pages/FormasDePagoPage";
import CuotasPage from "./pages/CuotasPage";
import ContraPage from "./pages/ContraPage";
import DialogoImprimirFacturaPage from "./pages/DialogoImprimirFacturaPage";
import AltaCliente from "./pages/AltaCliente";
import { PagoQRPage } from "./pages/PagoQRPage";
import CierreTurnoPage from "./pages/CierreTurnoPage";
import ListadoQRPage from "./pages/ListadoQRPage";
import DashboardMini from "./pages/DashboardMini";
import ChoferesPage from "./pages/ChoferesPage";
import DatosChofer from "./pages/DatosChofer";
import DespachoManualPage from "./pages/DespachoManualPage";
import TransferenciaPage from "./pages/TransferenciaPage";
import ConfiguracionesPage from "./pages/ConfiguracionesPage";
import ConsultarPuntos from "./pages/ConsultarPuntos";
import ObtenerDNIPuntos from "./pages/ObtenerDNIPuntos";
import CanjesPage from "./pages/CanjesPage";
import SaldosCtacte from "./pages/SaldosCtacte";
import AcumulacionPuntoServiclubPage from "./pages/AcumulacionPuntosServiclubPage";

function App() {
  const navigate = useNavigate();
  const [isLogged, setIsLogged] = useState(false);
  const [versionActual, setVersionActual] = useState("");
  const [showVersionWarning, setShowVersionWarning] = useState(false);

  useEffect(() => {
    const versionCheck = async () => {
      try {
        const response = await version.getVersionCaldenOil();
        const versionData = response.versionCaldenOil;
        setVersionActual(versionData);

        const versionMinima = process.env.REACT_APP_CALDENOIL_MIN_VERSION;

        if (compareVersions(versionData, versionMinima) < 0) {
          setShowVersionWarning(true);
        }
      } catch (error) {
        console.error("Error al obtener la versión:", error);
      }
    };

    versionCheck();
    const isUserLogged = localStorage.getItem("token");
    setIsLogged(isUserLogged !== null);
    if (isUserLogged === null) {
      navigate("/");
    } else if (window.location.pathname === "/") {
      navigate("/home");
    }
  }, [navigate]);

  return (
    <>
      <CssBaseline />
      <div className="App">
        <Routes>
          {isLogged ? (
            <>
              <Route element={<Home />} path="/home" />
              <Route element={<ContraPage />} path="/contrapage" />
              <Route element={<Dashboard />} path="/dashboard" />
              <Route element={<ClientesSearchPage />} path="/clientessearchpage" />
              <Route element={<ArticulosSearchPage />} path="/articulossearchpage" />
              <Route element={<DespachosSearchPage />} path="/despachossearchpage" />
              <Route element={<FormasDePagoPage />} path="/formasdepagopage" />
              <Route element={<CuotasPage />} path="/cuotaspage" />
              <Route element={<DialogoImprimirFacturaPage />} path="/dialogoimprimirfacturapage" />
              <Route element={<TicketCanvas />} path="/ticket" />
              <Route element={<Comprobante />} path="/comprobante" />
              <Route element={<PagoConMercadopago />} path="/pagoConMercadopago" />
              <Route element={<FinalizarPago />} path="/finalizarPago" />
              <Route element={<SignaturePadComponent />} path="/firma" />
              <Route element={<PagoQRPage />} path="/pagoqrpage" />
              <Route element={<AltaCliente />} path="/altacliente" />
              <Route element={<CierreTurnoPage />} path="/CierreTurnoPage" />
              <Route element={<ListadoQRPage />} path="/listadoqrpage" />
              <Route element={<DashboardMini />} path="/dashboardmini" />
              <Route element={<ChoferesPage />} path="/choferesPage" />
              <Route element={<DatosChofer />} path="/datoschofer" />
              <Route element={<DespachoManualPage />} path="/despachomanualpage" />
              <Route element={<TransferenciaPage />} path="/transferenciapage" />
              <Route element={<ConfiguracionesPage />} path="/configuracionespage" />
              <Route element={<ConsultarPuntos />} path="/consultarPuntos" />
              <Route element={<ObtenerDNIPuntos />} path="/obtenerdnipuntos" />
              <Route element={<CanjesPage />} path="/canjesPage" />
              <Route element={<SaldosCtacte />} path="/saldosCtacte/:idCliente/:nombreCliente" />
              <Route element={<AcumulacionPuntoServiclubPage />} path="/acumulacionpuntosserviclub" />
            </>
          ) : (
            <Route element={<Login />} path="/" />
          )}
        </Routes>
      </div>

      {/* Footer siempre visible */}
      <Footer versionActual={versionActual} />

      {/* Cartel de versión incompatible sobre la app sin ocultar el fondo */}
      {showVersionWarning && (
        <Box
          sx={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Fondo semi-transparente
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1300,
          }}
        >
          <Box
            sx={{
              backgroundColor: "rgba(255, 255, 255, 1)",
              padding: "30px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "92%",
              maxWidth: "400px",
              textAlign: "center",
              boxShadow: 3,
            }}
          >
            <FiAlertTriangle style={{ fontSize: "4rem", color: "#d32f2f" }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", mt: 2 }}>
              Versión incompatible
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Se está utilizando la versión <strong>{versionActual}</strong> de CaldenOil. <br /> Se requiere la versión minima{" "}
              <strong>{process.env.REACT_APP_CALDENOIL_MIN_VERSION}</strong>.
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: "#d32f2f", fontWeight: "bold" }}>
              Actualiza CaldenOil para continuar.
            </Typography>
            {/* <Button variant="contained" color="error" sx={{ mt: 3 }} onClick={() => window.location.reload()}>
              Actualizar
            </Button> */}
          </Box>
        </Box>
      )}
    </>
  );
}

export default App;
