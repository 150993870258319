import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Button, Dialog, Alert, FormControl, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import MenuSection from "../components/MenuSection";
import venta from "../api/generarVentaQR";
import { useNavigate } from "react-router-dom";
import cancelarVentaQR from "../api/cancelarVentaQR";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import generarFacturaJson from "../helpers/generarFacturaJson";
import { useTheme } from "@mui/material/styles";
import { setTarjetaID } from "../helpers/pagosHelper"; // Importar el helper
import ventaEfectivoQR from "../api/efectivoQR";
import qr from "../images/qr-shell.png";

export const PagoQRPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const qrImage = localStorage.getItem("urlPosicionQR");
  const posicionQR = Number(localStorage.getItem("idPosicionQR"));
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const IdEmpleadoVendedor = null; // HARDCODEADO
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  var prefijo = localStorage.getItem("prefijoFront") || null;
  const navigate = useNavigate();
  const idTalonarioQR = +localStorage.getItem("idTalonarioQR");
  const conListadoQR = JSON.parse(localStorage.getItem("conListadoQR"));
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
  const [confirmCancelModalOpen, setConfirmCancelModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("confirm");
  const [isCancelDisabled, setIsCancelDisabled] = useState(!JSON.parse(localStorage.getItem("puedeCancelarQR"))); // Estado para deshabilitar el botón Cancelar
  console.log(typeof(isCancelDisabled));
  useEffect(() => {
    // Reemplaza la entrada actual en el historial
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, [navigate]);

  const handleBackButton = () => {
    handleCancel();
  };

  useEffect(() => {
    const obtenerIdTarjeta = async () => {
      const tarjetasData = JSON.parse(localStorage.getItem("tarjetas")); // Obtenemos el array de tarjetas
      localStorage.setItem("vengoDeQR", true);
      const idTarjeta = await setTarjetaID(null, tarjetasData);
      console.log(idTarjeta);
      generarFactura(idTarjeta);
    };

    const generarFactura = IdTarjeta => {
      const facturaJson = generarFacturaJson(
        null, // Aquí podrías pasar CuponClover si es necesario
        puesto,
        cliente,
        facturaInfo,
        totalProductos,
        prefijo,
        emiteFacturayRemito,
      );

      // Agregar campos adicionales que no estaban en el helper
      facturaJson.cabecera.IdTarjeta = IdTarjeta;
      facturaJson.cabecera.IdTerminalTarjeta = idTalonarioQR;
      facturaJson.cabecera.IdPosicionQR = posicionQR; //1 hardcodeado shellbox pago en efectivo -

      localStorage.setItem("facturaJson", JSON.stringify(facturaJson));
    };

    obtenerIdTarjeta();
  }, []);

  const handleOptionChange = event => {
    setSelectedOption(event.target.value);
  };

  const handleModalAccept = async () => {
    setConfirmCancelModalOpen(false);
    if (selectedOption === "confirm") {
      setDialogMessage("Operación confirmada con éxito");
      setIsCancelDisabled(true); // Desactiva el botón después de hacer clic
      try {
        console.log("entro hasta aca?");
        const response = await ventaEfectivoQR.efectivoQR();
        setDialogMessage(response.data.msg);
        let idsMovimientosFac = response.data.movimientos;
        localStorage.setItem("idsMovimientosFac", JSON.stringify(idsMovimientosFac));
      } catch (error) {
        console.log(error);
      }
      setDialogSeverity("success");
    } else {
      handleCancel();
    }
    setDialogOpen(true);
  };

  const generarVenta = async () => {
    try {
      const response = await venta.ventaQR();
      console.log(response);

      if (response && response.data) {
        localStorage.setItem("pagoConQR", true);

        if (response.data.idPagoElectronico) {
          localStorage.setItem("idPagoElectronico", response.data.idPagoElectronico);

          setConfirmCancelModalOpen(true); // Abre el modal de confirmación/cancelación
        } else {
          let idsMovimientosFac = response.data;
          localStorage.setItem("idsMovimientosFac", JSON.stringify(idsMovimientosFac));
          setDialogMessage("El pago se procesó con éxito");
          setDialogSeverity("success");
          setDialogOpen(true);
          setLoading(false);
        }
      } else if (response && response.error) {
        console.log(response.error);
        setDialogMessage(response.error); // Mostrar el mensaje de error en el diálogo
        setDialogSeverity("error");
        setDialogOpen(true);
        setLoading(false);
      } else {
        setDialogMessage("Error desconocido.");
        setDialogSeverity("error");
        setDialogOpen(true);
        setLoading(false);
      }
    } catch (error) {
      console.log("Error al generar la venta:", error);
      setDialogMessage("Error al generar la venta.");
      setDialogSeverity("error");
      setDialogOpen(true);
      setLoading(false);
    }
  };

  useEffect(() => {
    setTimeout(() => {
      generarVenta();
    }, 1000);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (dialogOpen && dialogSeverity === "success") {
      const timer = setTimeout(() => {
        setDialogOpen(false);
        navigate("/contrapage");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (dialogOpen && dialogSeverity === "error") {
      const timer = setTimeout(() => {
        const esPlaya = localStorage.getItem("solicitarAforadores");
        if (esPlaya === "true") {
          setDialogOpen(false);
          navigate("/dashboard");
        } else {
          setDialogOpen(false);
          navigate("/dashboardmini");
        }
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [dialogOpen, dialogSeverity, navigate]);

  const handleCancel = async () => {
    setIsCancelDisabled(true); // Desactiva el botón después de hacer clic
    try {
      const response = await cancelarVentaQR.cancelar();
      setDialogMessage(response.data.msj);
      setDialogSeverity("error");
      setDialogOpen(true);
      setLoading(false);
    } catch (error) {
      console.log("Error al cancelar la venta:", error);
    }
  };

  const handleRetry = () => {
    setLoading(true);
  };

  return (
    <>
      <MenuSection />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        {loading ? (
          <>
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", marginTop: 2 }}>
              Generando QR
            </Typography>
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#000",
                textAlign: "center", // Centra el texto horizontalmente
                width: "100%", // Asegura que el texto ocupe todo el ancho del contenedor
              }}
            >
              Escanea el QR para confirmar el pago, por favor espere...
            </Typography>

            <CircularProgress sx={{ marginBottom: 1, color: theme.bandera.colorSecundario }} />

            {!conListadoQR && (
              <Box
                component="img"
                src={qrImage}
                alt="QR Code"
                sx={{
                  width: 250,
                  height: 250,
                }}
              />
            )}

            <Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 1, marginTop: 1, color: "#000" }}>
              Monto: ${totalProductos.toFixed(2)}
            </Typography>

            <Button
              variant="contained"
              onClick={handleCancel}
              disabled={isCancelDisabled} // Deshabilita el botón después de hacer clic
              sx={{
                fontWeight: "bold",
                padding: "0.6rem",
                fontSize: 16,
                whiteSpace: "nowrap",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
              }}
            >
              CANCELAR
            </Button>
          </>
        )}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
          // navigate("/contrapage");
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: dialogSeverity === "success" ? "#4caf50" : "#f44336",
            padding: "60px",
            color: "white",
          }}
        >
          <Alert
            severity={dialogSeverity}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" style={{ color: "white", fontSize: "80px" }} />,
              error: <HighlightOffIcon fontSize="inherit" style={{ fontSize: "80px" }} />,
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
          >
            {dialogMessage}
          </Alert>
          <CircularProgress
            sx={{
              marginTop: "20px",
              color: "white",
            }}
          />
        </Box>
      </Dialog>

      <Dialog open={confirmCancelModalOpen} onClose={() => setConfirmCancelModalOpen(false)}>
        <Box sx={{ padding: 4, textAlign: "center", backgroundColor: theme.palette.background.default }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
            Confirmación de recepción para pagos electrónicos
          </Typography>
          <FormControl component="fieldset">
            <RadioGroup value={selectedOption} onChange={handleOptionChange}>
              <FormControlLabel value="confirm" control={<Radio />} label="Confirmar" />
              <FormControlLabel value="cancel" control={<Radio />} label="Cancelar" />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleModalAccept}
            sx={{
              mt: 2,
              backgroundColor: theme.bandera.colorSecundario,
              fontWeight: "bold",
              ":hover": {
                backgroundColor: theme.bandera.colorSecundario,
              },
            }}
          >
            ACEPTAR
          </Button>
        </Box>
      </Dialog>
    </>
  );
};

export default PagoQRPage;
