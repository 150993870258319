import React, { useState, useEffect } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import LogoutIcon from "@mui/icons-material/Logout";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";
import { useNavigate } from "react-router-dom";
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Box } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import SettingsIcon from "@mui/icons-material/Settings";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import sesion from "../api/cerrarSesion";
import balizaGris from "../images/baliza-gris.png";
import balizaAmarillo from "../images/baliza-amarillo.png";
import balizaNaranja from "../images/baliza-naranja.png";
import balizaRojo from "../images/baliza-rojo.png";
import balizaYPF from "../images/baliza-ypf.png";
import balizaMagenta from "../images/baliza-magenta.png";
import { compareVersions } from "compare-versions";
import version from "../api/versionCaldenOil";
import Footer from "./Footer";
import Alert from "@mui/material/Alert";

const options = [
  { label: "Despacho Manual", icon: <LocalGasStationIcon /> },
  { label: "Consultar Puntos", icon: <LoyaltyIcon />, onlyShell: true },
  { label: "Configuraciones", icon: <SettingsIcon /> },
  { label: "Cerrar Sesión", icon: <LogoutIcon /> },
];

const ITEM_HEIGHT = 70;

const MenuSection = () => {
  const [hasClickedBaliza, setHasClickedBaliza] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();
  const user = localStorage.getItem("user");
  const theme = useTheme();
  const nic = localStorage.getItem("formattedNic");
  const bandera = localStorage.getItem("bandera");
  const [showUpdateWarning, setShowUpdateWarning] = useState(false);
  const [versionActual, setVersionActual] = useState("");
  const [blink, setBlink] = useState(true);
  const [showMessage, setShowMessage] = useState(false);

  const balizaColores = {
    shell: balizaRojo,
    puma: balizaRojo,
    ypf: balizaYPF,
    axion: balizaMagenta,
  };

  // Si la bandera no está en el objeto, usa balizaNaranja por defecto
  const balizaSeleccionada = balizaColores[bandera] || balizaNaranja;

  useEffect(() => {
    const checkVersionWarning = async () => {
      try {
        const response = await version.getVersionCaldenOil();
        const versionData = response.versionCaldenOil;
        setVersionActual(versionData);

        const warnVersion = process.env.REACT_APP_CALDENOIL_WARN_VERSION;

        if (compareVersions(versionData, warnVersion) < 0) {
          setShowUpdateWarning(true);
        }
      } catch (error) {
        console.error(error.response.status);

        if (error.response.status == 404) {
          setShowUpdateWarning(true);
        }
        console.error("Error al obtener la versión:", error);
      }
    };

    checkVersionWarning();
  }, []);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionClick = async option => {
    if (option === "Cerrar Sesión") {
      localStorage.removeItem("articulosArray");
      localStorage.removeItem("clientesArray");
      localStorage.removeItem("responsePagoEfectivoJava");
      localStorage.removeItem("CuponClover");
      localStorage.removeItem("pagoConCtaCte");
      localStorage.removeItem("puestosArray");
      localStorage.removeItem("cliente");
      localStorage.removeItem("passEmpleados");
      localStorage.removeItem("imprimirPrecioSinImpuestosYTributosEnTIKFAB");
      try {
        console.log("entro aca?");
        const response = await sesion.cerrarSesion();
        alert(response);
      } catch (error) {
        console.log(error);
      }
      localStorage.removeItem("serialNumberKeyClover");
      localStorage.removeItem("token");
      navigate("/");
    } else {
      navigate(`/${option.toLowerCase().replace(" ", "")}page`);
    }
  };

  const handleBack = () => {
    const restrictedPaths = ["/ticket", "/facturaA", "/facturaB", "/remito", "/contrapage", "/dialogoimprimirfacturapage", "/pagoqrpage"];
    if (!restrictedPaths.includes(window.location.pathname)) {
      navigate("/home");
    }
  };

  const handleBalizaClick = () => {
    setShowMessage(prev => !prev);
    setHasClickedBaliza(true);
  };

  const filteredOptions = options.filter(option => {
    if (option.onlyShell && bandera !== "shell") return false;
    if (["clover", "admin"].includes(user.toLowerCase())) return true;
    return localStorage.getItem("solicitarAforadores") === "false" ? option.label !== "Despacho Manual" : option.label !== "Configuraciones";
  });

  return (
    <>
      <div style={{ position: "absolute", top: 0, left: 0 }}>
        <AppBar style={{ backgroundColor: theme.bandera.colorHeader }}>
          <Toolbar>
            <Typography component="div" sx={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <img
                onClick={handleBack}
                src={theme.bandera.image}
                alt="Logo Calden Oil"
                style={{ width: theme.bandera.anchoLogo, height: "auto", cursor: "pointer" }}
              />
            </Typography>

            {nic === "http://pabloi.caldenoil.com:9009" && (
              <Typography component="div" sx={{ flexGrow: 1, fontWeight: "bold", fontSize: "30px", color: "black" }}>
                DEV{" "}
              </Typography>
            )}

            {showUpdateWarning && (
              <Box sx={{ position: "relative", display: "flex", alignItems: "center" }}>
                <Box
                  component="img"
                  //YPF NARANJA/DORADO
                  //AXION MAGENTA (NUEVO ARCHIVO)

                  //PUMA ROJO
                  //SHELL ROJO

                  //icono en rojo , fondo: gris claro, raya en rojo, texto negro o gris oscuro
                  src={balizaSeleccionada}
                  alt="Advertencia de actualización"
                  onClick={handleBalizaClick}
                  sx={{
                    width: 29,
                    height: 24,
                    cursor: "pointer",
                    filter: "brightness(110%) contrast(120%)",
                    animation: hasClickedBaliza ? "none" : "blink 1s infinite alternate",
                    "@keyframes blink": {
                      "0%": { opacity: 1 },
                      "50%": { opacity: 0.2 },
                      "100%": { opacity: 1 },
                    },
                  }}
                />
              </Box>
            )}

            <IconButton
              edge="end"
              sx={{ color: theme.bandera.colorIconoHeader }}
              aria-label="menu"
              id="long-button"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleClick}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            <Menu
              id="long-menu"
              MenuListProps={{ "aria-labelledby": "long-button" }}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{ style: { maxHeight: ITEM_HEIGHT * 5.5, width: window.innerWidth > 700 ? "25%" : "65%" } }}
            >
              {filteredOptions.map(option => (
                <MenuItem
                  sx={{ fontWeight: "bold", borderBottom: "1px solid #ccc", marginBottom: "4px", height: ITEM_HEIGHT + 10, gap: 1 }}
                  key={option.label}
                  onClick={() => handleOptionClick(option.label)}
                >
                  {option.icon}
                  {option.label}
                </MenuItem>
              ))}
            </Menu>
          </Toolbar>
        </AppBar>
        {showMessage && (
          <Box
            onClick={() => setShowMessage(false)}
            sx={{
              position: "fixed",
              top: 56,
              left: 0,
              width: "100%",
              height: "100vh",
              zIndex: 9999,
              backgroundColor: "rgba(0, 0, 0, 0.3)",
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-start",
            }}
          >
            <Alert
              severity="warning"
              variant="outlined"
              onClick={e => e.stopPropagation()}
              color="warning"
              sx={{
                justifyContent: "center",
                textAlign: "center",
                fontSize: "12px",
                backgroundColor: "#FFF3CD",
                borderRadius: 0,
                width: "100%",
                maxWidth: "600px",
                padding: "10px",
              }}
            >
              La versión utilizada ({versionActual}) de Calden Oil es inferior a la recomendada ({process.env.REACT_APP_CALDENOIL_WARN_VERSION}). Actualizar
              para evitar inconvenientes.
            </Alert>
          </Box>
        )}
      </div>
      <Footer />
    </>
  );
};
export default MenuSection;
