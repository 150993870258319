// helpers/pagoHelpers.js
import estadoDespacho from "../api/estadoDespacho";
import { CircularProgress } from "@mui/material";
const nic = localStorage.getItem("formattedNic");

export const actualizarDespachos = async () => {
  const despachosArray = JSON.parse(localStorage.getItem("despachosSeleccionados")) || [];
  let allUpdatesSuccessful = true;

  for (let idDespacho of despachosArray) {
    try {
      await estadoDespacho.actualizarDespacho(idDespacho, 1, 1);
    } catch (error) {
      console.error("Error al actualizar el despacho:", error);
      allUpdatesSuccessful = false;
    }
  }

  if (allUpdatesSuccessful) {
    localStorage.removeItem("despachosSeleccionados");
  }
};

export const handlePagoEvent = (setIsLoading, navigate) => {
  return async event => {
    const responsePagoConTarjetaJava = event.detail.success;
    const jsonString = JSON.stringify(event.detail.responsePagoConTarjeta);

    localStorage.setItem("CuponClover", jsonString);
    localStorage.setItem("responsePagoConTarjetaJava", responsePagoConTarjetaJava);

    if (responsePagoConTarjetaJava) {
      setIsLoading(true);
      await actualizarDespachos();

      await setTarjetaID(jsonString, JSON.parse(localStorage.getItem("tarjetas")));

      const CuponClover = JSON.parse(jsonString);
      if (CuponClover?.payment?.attributes?.note?.includes("MERCADO PAGO")) {
        localStorage.setItem("cargaPuntoShellBox", false);
      }

      navigate("/contrapage");
    }
  };
};

export const setTarjetaID = async (jsonString, tarjetasData) => {
  const CuponClover = JSON.parse(jsonString);
  const labelKey = CuponClover ? CuponClover.payment.tender.labelKey : "";
  const pagoQR = labelKey.includes("qr") ? "QR" : null; // Extrae "qr" si existe en labelKey
  const cardSymbol = CuponClover ? CuponClover.payment.transactionInfo?.cardSymbol : "";

  for (let tarjeta of tarjetasData) {
    // console.log(tarjeta);

    if (CuponClover) {
      if (tarjeta.codigoLapos?.includes(cardSymbol) || tarjeta.codigoComercio == "999999") {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        break; // Detiene el loop en la primera coincidencia
      } else if (tarjeta.codigoLapos === "LST") {
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        console.log(tarjeta.idTarjeta);
        break; // Detiene el loop en la primera coincidencia
      }
    }
    const tarjetaNombre = tarjeta.nombre ? tarjeta.nombre.toUpperCase() : "";
    const pagoElectronico = tarjeta.pagoElectronico ? tarjeta.pagoElectronico : false;

    if (localStorage.getItem("vengoDeQR") === "true") {
      if (tarjetaNombre.includes("YPF") && pagoElectronico) {
        // 5 - APP YPF
        // 2 - MP
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        // localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        console.log(tarjeta.idTarjeta);
        // localStorage.setItem("vengoDeQR", false);
        break; // Detiene el loop en la primera coincidencia
      } else if (tarjetaNombre.includes("SHELL") && pagoElectronico) {
        // 1 - SHELLBOX
        localStorage.setItem("idTarjeta", tarjeta.idTarjeta);
        console.log(tarjeta.idTarjeta);
        // localStorage.setItem("vengoDeQR", false);
        break; // Detiene el loop en la primera coincidencia
      }
    }
  }

  return localStorage.getItem("idTarjeta");
};

export const showLoadingSpinner = () => (
  <div
    style={{
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      backgroundColor: "rgba(255, 255, 255, 0.8)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 9999,
    }}
  >
    <CircularProgress />
  </div>
);
