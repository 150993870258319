import React, { useEffect, useState } from "react";
import { Typography, Box, CircularProgress, Button, Dialog, Alert } from "@mui/material";
import MenuSection from "../components/MenuSection";
import { useNavigate } from "react-router-dom";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import generarFacturaJson from "../helpers/generarFacturaJson";
import { useTheme } from "@mui/material/styles";
import { setTarjetaID } from "../helpers/pagosHelper";
import puntosServiclub from "../api/enviarAcumulacionServiClub";

export const AcumulacionPuntosServiclubPage = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const [qrListo, setQrListo] = useState(false);
  const qrImage = localStorage.getItem("urlPosicionQRServiclub");
  const posicionQR = Number(localStorage.getItem("idPosicionQRServiclub"));
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const emiteFacturayRemito = localStorage.getItem("emiteFacturayRemito");
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  const prefijo = localStorage.getItem("prefijoFront") || null;
  const navigate = useNavigate();
  const idTalonarioQR = +localStorage.getItem("idTalonarioQR");
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
  const [isCancelDisabled, setIsCancelDisabled] = useState(!JSON.parse(localStorage.getItem("puedeCancelarQR")));

  useEffect(() => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const handleBackButton = () => {
    handleCancel();
  };

  useEffect(() => {
    const obtenerIdTarjeta = async () => {
      const tarjetasData = JSON.parse(localStorage.getItem("tarjetas"));
      localStorage.setItem("vengoDeQR", true);
      const idTarjeta = await setTarjetaID(null, tarjetasData);
      generarFactura(idTarjeta);
    };

    const generarFactura = IdTarjeta => {
      const facturaJson = generarFacturaJson(null, puesto, cliente, facturaInfo, totalProductos, prefijo, emiteFacturayRemito);

      facturaJson.cabecera.IdTarjeta = IdTarjeta;
      facturaJson.cabecera.IdTerminalTarjeta = idTalonarioQR;
      facturaJson.cabecera.IdPosicionQR = posicionQR;

      localStorage.setItem("facturaJson", JSON.stringify(facturaJson));
    };

    obtenerIdTarjeta();
  }, []);

  const acumulacionPuntoServiclub = async () => {
    try {
      const response = await puntosServiclub.EnviarAcumulacionServiClub();

      if (response.status === 200) {
        console.log(JSON.stringify(response));
        setQrListo(true);
      } else {
        setDialogMessage("No se pudo generar el QR");
        setDialogSeverity("error");
        setDialogOpen(true);
      }
    } catch (error) {
      console.log("Error al generar el QR", error);
      setDialogMessage("No se pudo generar el QR");
      setDialogSeverity("error");
      setDialogOpen(true);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      acumulacionPuntoServiclub();
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (dialogOpen && dialogSeverity === "success") {
      const timer = setTimeout(() => {
        setDialogOpen(false);
        navigate("/finalizarpago");
      }, 3000);
      return () => clearTimeout(timer);
    } else if (dialogOpen && dialogSeverity === "error") {
      const timer = setTimeout(() => {
        navigate("/finalizarpago");
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [dialogOpen, dialogSeverity, navigate]);

  const handleCancel = async () => {
    setIsCancelDisabled(true);
    navigate("/finalizarpago");
  };

  return (
    <>
      <MenuSection />
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" height="100vh">
        {loading ? (
          <>
            <CircularProgress sx={{ color: theme.bandera.colorSecundario }} />
            <Typography variant="h5" sx={{ fontWeight: "bold", marginTop: 2 }}>
              Generando QR
            </Typography>
          </>
        ) : qrListo ? (
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#000",
                textAlign: "center",
                width: "100%",
              }}
            >
              Escanea el QR para acumular puntos
            </Typography>

            <CircularProgress sx={{ marginBottom: 1, color: theme.bandera.colorSecundario }} />

            <Box
              component="img"
              src={qrImage}
              alt="QR Code"
              sx={{
                width: 250,
                height: 250,
              }}
            />

            <Button
              variant="contained"
              onClick={handleCancel}
              disabled={isCancelDisabled}
              sx={{
                fontWeight: "bold",
                padding: "0.6rem",
                marginTop: "1rem",
                fontSize: 20,
                whiteSpace: "nowrap",
                backgroundColor: theme.bandera.colorSecundario,
                ":hover": {
                  backgroundColor: theme.bandera.colorSecundario,
                },
              }}
            >
              SALIR
            </Button>
          </>
        ) : (
          <>
            <Typography
              variant="h6"
              sx={{
                fontWeight: "bold",
                color: "#000",
                textAlign: "center",
                width: "100%",
              }}
            >
              No se pudo generar el QR
            </Typography>
          </>
        )}
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={() => {
          setDialogOpen(false);
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: dialogSeverity === "success" ? "#4caf50" : "#f44336",
            padding: "60px",
            color: "white",
          }}
        >
          <Alert
            severity={dialogSeverity}
            iconMapping={{
              success: <CheckCircleOutlineIcon fontSize="inherit" style={{ color: "white", fontSize: "80px" }} />,
              error: <HighlightOffIcon fontSize="inherit" style={{ fontSize: "80px" }} />,
            }}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "transparent",
              color: "white",
              fontSize: "1.25rem",
              fontWeight: "bold",
            }}
          >
            {dialogMessage}
          </Alert>
          <CircularProgress
            sx={{
              marginTop: "20px",
              color: "white",
            }}
          />
        </Box>
      </Dialog>
    </>
  );
};

export default AcumulacionPuntosServiclubPage;
