import axios from "axios";
import generarFacturaJson from "../helpers/generarFacturaJson";

const acumulaPuntos = async () => {
  const formattedNic = localStorage.getItem("formattedNic");
  const token = localStorage.getItem("token");
  const facturaInfo = JSON.parse(localStorage.getItem("facturaInfo"));
  const totalProductos = JSON.parse(localStorage.getItem("totalProductos"));
  const prefijo = localStorage.getItem("prefijoFront") || null;
  const clienteString = localStorage.getItem("cliente");
  const cliente = clienteString ? JSON.parse(clienteString) : null;
  const CuponClover = JSON.parse(localStorage.getItem("CuponClover"));
  const puesto = JSON.parse(localStorage.getItem("puesto"));
  let facturaJsonPuntos = generarFacturaJson(CuponClover, puesto, cliente, facturaInfo, totalProductos, prefijo, null);
  console.log(facturaJsonPuntos);

  const userData = {
    nic: formattedNic,
    token: token,
    datos: facturaJsonPuntos,
    serialNumberKeyClover: localStorage.getItem("serialNumberKeyClover"),
  };

  const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/Fidelizacion/sugerirAcumularServiclub`, userData);
  return response;
};

const sugerirAcumularServiclub = {
  acumulaPuntos,
};

export default sugerirAcumularServiclub;
